@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .coverButton{
        @apply px-6 py-2 border-[#242424] rounded-full uppercase text-xs tracking-normal sm:tracking-widest text-gray-500 transition-all
        hover:border-[#F7AB0A]/40 hover:text-[#F7AB0A]/40
    }

    .contactInput{
        @apply outline-none bg-slate-400/10 rounded-sm border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-[#F7AB0A]/40
        focus:text-[#F7AB0A]/40 hover:border-[#F7AB0A]/40
    }

    /* .wave {
        animation: wave-animation 1s linear infinite;
      }
      
      @keyframes wave-animation {
        0% {
          color: transparent;
        }
        10% {
          color: white;
        }
        20% {
          color: transparent;
        }
      } */
}